import {
  AppDownload,
  OwApp,
  PairedApp,
} from '../interfaces/domain/ow-app.modal';
import { DownloadCountDto } from '../interfaces/dtos/downloadCount.dto';
import { IAppService } from '../interfaces/services/app-service.interface';
import { DataFetchService } from './data-fetch.service';
import getConfig from 'next/config';
import { ServicesHandler } from './service-handler';
import Utils from '../utils/utils';
import { LocalizationService } from './localization-service';

export class AppService implements IAppService {
  private readonly config: any;

  constructor(config: any) {
    this.config = config;
  }

  // ---------------------------------------------------------------------------
  public async getApp(slug: string): Promise<OwApp> {
    const { appConfig } = this.config?.publicRuntimeConfig?.api;
    const app = await DataFetchService.get<OwApp>(
      `${appConfig}/${slug.toLowerCase()}`
    );

    if (!app) {
      return null;
    }

    this.createAppDownloadLinks(app);

    return app;
  }

  // ---------------------------------------------------------------------------
  public getAppDownloadCount = async (appId: string): Promise<string> => {
    try {
      const { appDownloadCount } = this.config?.publicRuntimeConfig?.api;
      const downloads = await DataFetchService.get<DownloadCountDto>(
        `${appDownloadCount}?appids=[%22${appId}%22]`
      );

      return downloads[appId];
    } catch {
      return null;
    }
  };

  // ---------------------------------------------------------------------------
  public getAppSeoNameByUid = async (appId: string): Promise<string> => {
    try {
      if (!appId) {
        return null;
      }

      const { categoryPageService } = ServicesHandler.getInstance(getConfig());
      const apps = await categoryPageService.getAppsLookup([appId]);

      const tile = apps[appId];
      if (!tile) {
        return null;
      }

      return tile.seoName;
    } catch {
      return null;
    }
  };

  // ---------------------------------------------------------------------------
  public getPairedApps = async (appIds: string[]): Promise<PairedApp[]> => {
    try {
      if (!appIds || appIds.length === 0) {
        return null;
      }

      const { categoryPageService } = ServicesHandler.getInstance(getConfig());
      const apps = await categoryPageService.getAppsLookup([...appIds]);

      const response: PairedApp[] = [];
      const baseUrl = this.config?.publicRuntimeConfig?.base?.baseUrl;

      for (const app in apps) {
        response.push({
          appId: apps[app].id,
          name: apps[app].title,
          description: apps[app].shortDescription,
          imgSrc: apps[app].iconUrl,
          link: new URL(`${baseUrl}/app/${apps[app].seoName}`).toString(),
        });
      }

      return response;
    } catch {
      return null;
    }
  };

  // ---------------------------------------------------------------------------
  private createAppDownloadLinks(app: OwApp): void {
    if (!app?.alternativeDownloads || app?.alternativeDownloads.length === 0) {

      app.alternativeDownloads = [];

      const fallBackDownloadItem: AppDownload = {
        label: LocalizationService.Translate('downloadApp'),
        platform: 'Windows',
        appId: app.id,
      };

      app.alternativeDownloads.push(fallBackDownloadItem);
    }

    app.alternativeDownloads.forEach(item => {
      this.constructDownloadUrl(app, item);
    });
  }

  // ---------------------------------------------------------------------------
  private constructDownloadUrl(app: OwApp, item: AppDownload): void {
    if (item.url) {
      item.url = Utils.appendHTTPSIfMissing(item.url);;
      return;
    }

    const downloadBaseUrl =
      this.config?.publicRuntimeConfig?.base?.downloadBaseUrl;

    const downloadUrlPath = '/install/Download';
    const url = new URL(`${downloadBaseUrl}${downloadUrlPath}`);

    if (app.title) {
      url.searchParams.append('Name', Utils.sanitizeString(app.title));
    }

    if (item.appId) {
      url.searchParams.append('ExtensionId', Utils.sanitizeString(item.appId));
    }

    url.searchParams.append('channel', 'web_dl_btn');
    item.url = url.toString();
  }
}
