import { HardCodedPageCategories } from '../data/site-pages/hard-coded-app-pages';
import { OwCarousel } from '../interfaces/domain/ow-carousel.modal';
import { OWCategoryPage } from '../interfaces/domain/ow-category-page.modal';
import {
  AppsLookupResponse,
  CarouselConfigResponse,
  ICategoryPageService,
  ShelveConfigResponse,
} from '../interfaces/services/category-page-service';
import { DataFetchService } from './data-fetch.service';
import { DownloadUtils } from './download-utils';
import { PromiseAwaiter } from './promise-awaiter';
import Utils from '../utils/utils';

// -----------------------------------------------------------------------------
const kPathToAppTilesConfig = '/app-tile-config?appIds=';
const kPathToShelveConfig = '/shelves-config';
const kPathToCarouselConfig = '/carousel-config';

// -----------------------------------------------------------------------------
export class CategoryPageService implements ICategoryPageService {
  private readonly config: any;
  private dlUtils: DownloadUtils;

  constructor(config: any) {
    this.config = config;
    this.dlUtils = new DownloadUtils(this.config);
  }

  // ---------------------------------------------------------------------------
  public async getCategoryPage(slug: string): Promise<OWCategoryPage> {
    try {
      if (!HardCodedPageCategories[slug]) {
        return null;
      }

      const categoryPage = Utils.deepCopy<OWCategoryPage>(
        HardCodedPageCategories[slug]
      );

      await this.appendCarousel(categoryPage);
      await this.appendShelves(categoryPage);
      await this.appendAppTiles(categoryPage);

      return categoryPage;
    } catch (error) {
      console.error('Failed constructing category page', error);
      return null;
    }
  }

  // ---------------------------------------------------------------------------
  public async getAppsLookup(apps: string[]): Promise<AppsLookupResponse> {
    try {
      const baseURL = this.config.publicRuntimeConfig.base.InternalStoreApiBase;
      const appConfigsURL = `${baseURL}${kPathToAppTilesConfig}${JSON.stringify(
        apps
      )}`;

      const promises = [
        DataFetchService.get<AppsLookupResponse>(appConfigsURL),
      ];

      const responses = await PromiseAwaiter.wait(promises);
      const appsResponse = responses[0] as AppsLookupResponse;
      if (!appsResponse) {
        return null;
      }

      return appsResponse;
    } catch (error) {
      console.error('Failed obtaining apps lookup', error);
      return null;
    }
  }

  // ---------------------------------------------------------------------------
  public async getShelves(
    shelveNames: string[] = []
  ): Promise<ShelveConfigResponse> {
    const baseURL = this.config.publicRuntimeConfig.base.InternalStoreApiBase;
    const appsShelveUrl = `${baseURL}${kPathToShelveConfig}`;

    const shelvesRes = await DataFetchService.get<ShelveConfigResponse>(
      appsShelveUrl
    );

    if (!shelvesRes) {
      return null;
    }

    if (shelveNames.length === 0) {
      return shelvesRes;
    }

    const foundShelves = {};

    for (const shelveName of shelveNames) {
      if (!shelvesRes[shelveName]) {
        continue;
      }

      foundShelves[shelveName] = shelvesRes[shelveName];
    }

    return foundShelves;
  }

  // ---------------------------------------------------------------------------
  public async getCarouselByName(name: string): Promise<OwCarousel> {
    if (!name) {
      return;
    }

    const baseURL = this.config.publicRuntimeConfig.base.InternalStoreApiBase;
    const carouselUrl = `${baseURL}${kPathToCarouselConfig}`;

    const carouselRes = await DataFetchService.get<CarouselConfigResponse>(
      carouselUrl
    );

    if (!carouselRes || !carouselRes[name]) {
      return null;
    }

    carouselRes[name].slides.sort((a, b) => a?.order - b?.order);

    return carouselRes[name];
  }

  // ---------------------------------------------------------------------------
  private async appendLookupAppTiles(
    apps: AppsLookupResponse,
    categoryPage: OWCategoryPage
  ): Promise<OWCategoryPage> {
    try {
      if (!apps) {
        return categoryPage;
      }

      categoryPage?.shelves.forEach(async shelf => {
        if (shelf.type === 'banner') {
          return;
        }

        shelf?.children?.forEach((shelfApp, index) => {
          const tile = apps[shelfApp.id];
          try {
            if (!tile) {
              return;
            }

            shelf.children[index] = tile;
          } catch {}
        });
      });

      return categoryPage;
    } catch (error) {
      console.error('Failed appending apps lookup', error);
      return null;
    }
  }

  // ---------------------------------------------------------------------------
  private async appendCarousel(categoryPage: OWCategoryPage): Promise<void> {
    if (!categoryPage.carousel && !categoryPage.carousel?.name) {
      return;
    }

    categoryPage.carousel = await this.getCarouselByName(
      categoryPage.carousel.name
    );
  }

  // ---------------------------------------------------------------------------
  private async appendShelves(categoryPage: OWCategoryPage): Promise<void> {
    const shelveNames = Array.from(categoryPage?.shelves).map(s => s.name);
    const shelvesConfig = await this.getShelves(shelveNames);

    categoryPage.shelves?.forEach((shelve, index) => {
      if (shelve.type === 'banner') {
        return;
      }

      if (!shelvesConfig || !shelvesConfig[shelve.name]) {
        return;
      }

      categoryPage.shelves[index] = {
        ...shelvesConfig[shelve.name],
        ...categoryPage.shelves[index],
      };
    });
  }

  // ---------------------------------------------------------------------------
  private async appendAppTiles(categoryPage: OWCategoryPage): Promise<void> {
    const pageApps = [];
    categoryPage.shelves?.forEach((shelve, index) => {
      if (shelve.type === 'banner') {
        return;
      }

      categoryPage.shelves[index]?.children?.forEach((app: any) => {
        if (pageApps.includes(app.id)) {
          return;
        }

        pageApps.push(app.id);
      });
    });

    const apps = pageApps.length > 0 ? await this.getAppsLookup(pageApps) : {};

    if (!apps) {
      throw new Error('Failed to obtain apps lookup');
    }

    for (const app in apps) {
      this.dlUtils.createAppDownloadLinks(apps[app]);
    }

    await this.appendLookupAppTiles(apps, categoryPage);
  }
}
