import { OwAppTile } from '../interfaces/domain/ow-app-tile.modal';
import { ISearchService } from '../interfaces/services/search-service.interface';
import { AppsLookupResponse } from '../interfaces/services/category-page-service';
import { DataFetchService } from './data-fetch.service';
import { DownloadUtils } from './download-utils';

// ---------------------------------------------------------------------------
export class LocalSearchService implements ISearchService {
  private readonly config: any;
  private dlUtils: DownloadUtils;

  // ---------------------------------------------------------------------------
  constructor(config: any) {
    this.config = config;
    this.dlUtils = new DownloadUtils(this.config);
  }

  // ---------------------------------------------------------------------------
  public async getSearchItems(name: string): Promise<OwAppTile[]> {
    try {
      if (!name) {
        return [];
      }

      if (name?.length < 3) {
        return [];
      }

      return this.getAppsBySearchTerm(name);
    } catch (e) {
      console.error(`Failed to get search for: ${name}. ${e.message}`);
    }

    return [];
  }

  // ---------------------------------------------------------------------------
  // Note(advoyris) - in case no apps are requested from "AppsLookupResponse" it
  // will return all of the apps
  private async getAppsBySearchTerm(searchTerm: string): Promise<OwAppTile[]> {
    const url = this.config.publicRuntimeConfig.api.search;
    try {
      const { data } = await DataFetchService.get<AppsLookupResponse>(
        `${url}?term=${searchTerm}`
      );

      if (!data) {
        return [];
      }

      const appsArray = Object.entries(data).map(
        app => app[1]
      ) as unknown as OwAppTile[];

      if (appsArray?.length === 0) {
        return appsArray;
      }

      for (const app in appsArray) {
        this.dlUtils.createAppDownloadLinks(appsArray[app]);
      }

      return appsArray.filter(app => !app.hidden && !app.disabled);
    } catch (error) {
      return [];
    }
  }
}
